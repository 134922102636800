<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-0">
                {{ "Đăng nhập" }}
              </h1>
            </div>
          </template>

          <v-card-text class="text-center">
            <v-tabs
              v-if="$route.query.admin"
              height="40px"
              v-model="tab"
              fixed-tabs
            >
              <v-tab>
                Ban điều hành
              </v-tab>
              <v-tab>
                Giám thị
              </v-tab>
              <v-tab>
                Quản trị
              </v-tab>
            </v-tabs>
            <v-form v-if="tab == 2" ref="form" lazy-validation v-model="valid">
              <v-text-field
                color="secondary"
                v-model="form.email"
                :disabled="loading"
                label="Email"
                :rules="rule.nameRules"
                prepend-icon="mdi-account"
              />

              <v-text-field
                class="mb-8"
                :append-icon="!show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                color="secondary"
                v-model="form.password"
                :disabled="loading"
                :rules="rule.passwordRules"
                label="Mật khẩu"
                @keyup.enter="login"
                prepend-icon="mdi-lock-outline"
                :type="show ? 'text' : 'password'"
              />
              <pages-btn
                large
                color
                depressed
                @click="login"
                :loading="loading"
                class="v-btn--text success--text"
                >Đăng nhập
              </pages-btn>
            </v-form>
            <template v-else>
              <pages-btn
                large
                color="green"
                depressed
                @click="getMicrosoftLoginPage"
                :loading="loading"
                class=" mt-3"
                >Đăng nhập với tài khoản Microsoft
              </pages-btn>
            </template>

            <div class="mt-4" style="font-weight: bold" v-if="examination">
              {{ message }}
            </div>
            <p
              v-if="
                $store.state.user.user &&
                  examination &&
                  examination.checkInStudents &&
                  message &&
                  new Date(examination.startTime) < new Date()
              "
            >
              Bạn đang đăng nhập với email:
              <span style="font-weight: bold">{{
                $store.state.user.user.email
              }}</span
              >, nếu sai
              <a style="font-weight:bold" href="/msal/student"
                >hãy bấm vào link này</a
              >
              để vào lại email của mình.
            </p>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { getNearestExamination } from "@/api/examination";
import dayjs from "dayjs";

export default {
  name: "PagesLogin",

  components: {
    PagesBtn: () => import("@/layouts/pages/components/Btn")
  },
  watch: {
    tab(val) {
      if (val == 2) {
        if (this.$store.state.user.auth)
          window.location.href = "/admin/original-exams";
      }
    }
  },
  data: () => ({
    valid: true,
    show: false,
    tab: 0,
    loading: false,
    form: {
      email: "",
      password: ""
    },
    examination: undefined,
    message: undefined,
    rule: {
      nameRules: [v => !!v || "Bạn chưa nhập tên đăng nhập"],
      passwordRules: [v => !!v || "Bạn chưa nhập password"]
    },
    socials: [
      {
        href: "#",
        icon: "mdi-facebook"
      },
      {
        href: "#",
        icon: "mdi-twitter"
      },
      {
        href: "#",
        icon: "mdi-github"
      }
    ]
  }),
  computed: {
    examStartTime() {
      if (this.examination) {
        return dayjs(this.examination.startTime).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return undefined;
      }
    }
  },
  async mounted() {
    const result = await getNearestExamination();
    this.examination = result.data;
    const msg = this.$store.state.app.errorMessage;
    if (msg) {
      this.message = msg;
      this.$store.commit("app/SET_ERROR_MESSAGE", undefined);
    }
  },
  methods: {
    login() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.loading = true;

      this.$store
        .dispatch("user/login", this.form)
        .then(homeUrl => {
          this.loading = false;
          // window.location.reload();
          this.$router.replace("/");
        })
        .catch(error => {
          this.loading = false;
          if (error.response.data.error === "Invalid Credentials") {
            this.$snackbar("Sai tài khoản hoặc mật khẩu", "error");
            this.loading = false;
          }
        });
    },
    getMicrosoftLoginPage() {
      if (this.$store.state.user.auth) {
        window.location.href = "/lecturer";
      } else {
        const host =
          process.env.NODE_ENV === "development"
            ? "http://localhost:3333/msal/"
            : "/msal/";
        const param = "admin";
        const origin = window.location.origin
        window.location.href = host + param + '?origin=' + origin;
      }
    }
  }
};
</script>
